@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@1,500&family=Karla:wght@300&family=Major+Mono+Display&display=swap');

$light-font-sans: 'Karla', sans-serif;
$monospace-font: 'Major Mono Display', monospace;
$bold-italic: 'Arimo', sans-serif;

$royal-purple: #240046;
$fuschia: #B7094C;
$fuschia-shadow: #A01A58;
$dark-turquoise: #455E89;
$turquoise: #0091AD;

$fuchsia-light: #C45AB3;
$violet: #631A86; 
$rose-dust: #A2666F; 
$light-coral: #F49390;
$bright-rose: #F45866; 

$colors: (
    #B7094C,
    "#A01A58",
    "#892B64",
    "#723C70",
    "#5C4D7D",
    "#455E89",
    "#2E6F95",
    "#1780A1",
    "#0091AD",
);

$light-colors: (
    #A2666F,
    #F49390, 
    #F45866,
    #C45AB3,
    #631A86,
)