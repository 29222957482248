@import 'variables';

.about_wrapper {
    width: 50vw;
    font-size: 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.quote {
    font-family: $monospace-font;
    color: whitesmoke;
    max-width: 400px;
}

.about_paragraph {
    font-family: $light-font-sans;
    color: whitesmoke;
}

@media only screen and (max-width: 600px) {
    .about {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .about_wrapper {
        width: 80%;
        font-size: 20px;
    }
  }

.about_salt {
    text-decoration: none;
    color: $fuschia;
}

.about_salt:hover {
    color: #02b9dd;
}

