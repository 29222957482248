@import 'variables';

.skills-header {
    font-family: $light-font-sans;
    font-size: 20px;
    padding: 7px;
    border-bottom: 3px solid $turquoise;
    border-right: 10px solid $turquoise;
}

.skills-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.skill {
    height: 40px;
    display: flex;
    width: 170px;
    align-items: center;
    margin: 5px;

    .skill-badge {
        font-size: 27px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .skill-name {
        font-family: $light-font-sans;
        font-size: 18px;
        color: whitesmoke;
    }
}

@media only screen and (max-width: 600px) {
    .skills-container {
        width: 80%;
        font-size: 14px;
        margin-top: 20px;
    }

    .skill {
        width: 90vw;
    }
}
