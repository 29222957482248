@import 'variables';

.footer_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: $fuschia;
    height: 60px;
    bottom: 0;
    position: fixed;
    width: 100%;
}

.footer_ul {
    padding: 0;
}

.footer_link {
    margin: 10px;
    font-size: 18px;
    text-decoration: none;
    color: whitesmoke;
    font-family: $light-font-sans;
}

.footer_link:active {
    background-color: $turquoise;
}

@media only screen and (min-width: 600px) {
    .footer_container {
        display: none;
    }
  }

