@import "variables";

.greeting_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  min-height: 100vh;
  color: whitesmoke;
}

.hello-icon-container {
  display: flex;
  align-items: center;

  .hello-icon {
    font-size: 40px;
    padding: 10px;
  }
}

.greeting_intro {
  font-size: 50px;
}

.greeting_intro,
.greeting_details {
  font-family: $light-font-sans;
  text-align: center;
}

.greeting_details {
  margin-bottom: 75px;
}

@media only screen and (max-width: 600px) {
  .greeting_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .greeting_intro,
  .greeting_details {
    width: 80%;
  }
}
