@import 'variables';

body {
    margin: 0 auto;
    background-color: $royal-purple;
}

.content_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 250px;
    background-color: $royal-purple;
}

.content_wrapper_light {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 250px;
    background-color: whitesmoke;
    color: black;
}

@media only screen and (max-width: 600px) {
    .content_wrapper {
        margin: 0px 0px 100px 0px;
    }
  }