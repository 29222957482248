@import 'variables';

.contact {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $turquoise;
    width: 70vw;
    max-width: 600px;
    height: 600px;
}

.decoration {
    box-shadow: inset 5px 10px $fuschia-shadow;
    width: 70vw;
    max-width: 600px;
    height: 600px;
    margin-top: 50px;
    margin-left: -575px;
    z-index: -1;
    background-color: $dark-turquoise;
}

@media only screen and (max-width: 600px) {
    .contact {
        width: 100%;
    }

    .contact_container {
        width: 90vw;
    }

    .decoration {
        display: none;
    }
  }
.contact_form {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.contact_label {
    font-size: 17px;
    font-family: $light-font-sans;
    color: whitesmoke;
    margin: 10px 0px 10px 0px;
}

.contact_input {
    height: 25px;
    border-radius: 5px;
    padding-left: 10px;
    border-style: none;
    font-family: $light-font-sans;
    background-color: white;
}

.contact_input--inquiry {
    padding: 10px;
    height: 200px;
    resize: none;
}

.contact_button--submit {
    margin-top: 15px;
    height: 30px;
    border-radius: 10px;
    border-style: none;
    width: 250px;
    font-family: $light-font-sans;
    background-color: $fuschia;
    color: whitesmoke;
}

.contact_button--submit:hover {
    color: lightslategray;
    text-shadow: .5px .5px whitesmoke;
    filter: blur(0px);
    -webkit-filter: blur(0px);
}

.contact_button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.contact_links {
    display: flex;
    justify-content: right;
}

.contact_links--a {
    font-size: 30px;
    color: $fuschia;
    margin-left: 10px;
}

